.contact-container {
  padding: 2rem 2rem 4rem 2rem;
  background-color: rgb(238, 247, 243);
}

.contact-content {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.contact-item span {
  font-size: 1rem;
}
