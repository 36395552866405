.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  margin-top: 4rem;
  background-color: rgb(238, 247, 243);
}

.home-image {
  max-width: 35%;
  margin: 2rem 2rem 0 0;
  border-radius: 10px;
}

.home-content {
  max-width: 65%;
  text-align: justify;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  .home-image {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .home-content {
    max-width: 100%;
  }
}
