.about-container {
  padding: 2rem;
  background-color: rgb(238, 247, 243);
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  margin-bottom: 2rem;
}

.icon {
  margin: 3px 10px 0 0;
}

.text {
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.text h3 {
  margin: 0;
}

.text p {
  margin-bottom: 0.25rem;
}

ul {
  padding-inline-start: 20px;
}
