footer {
  position: fixed;
  bottom: 0;
  background-color: #f7f7f7;
  text-align: center;
  padding: 0.5rem;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

footer p {
  margin: 0;
  font-size: 0.8rem;
  color: #333;
}
