.services-container {
  padding: 2rem;
  background-color: rgb(238, 247, 243);
}

.services-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service {
  text-align: center;
  margin: 2rem;
}

.service-icon {
  width: 7rem;
  height: 7rem;
  margin-bottom: 0.75rem;
}

.service p {
  max-width: 10rem;
  margin: 0 auto;
  line-height: 1.3;
}
