header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

h1 {
  margin: 0;
  font-size: 1.5rem;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company {
  font-size: x-large;
  font-weight: bold;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-right: 1rem;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
}

nav ul li a:hover {
  background-color: #333;
  color: #fff;
}

.icon-img {
  height: 3rem;
  margin-right: 0.75rem;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  nav ul {
    display: none;
  }
  .company {
    font-size: larger;
    font-weight: bold;
  }
}
