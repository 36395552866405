.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  height: 100%;
  max-height: calc(100vh - (85px + 30px));
  /* difference between the viewport height (100vh) and the combined heights of the header and footer elements, plus any margins or padding */
}
